/* General Navbar styling */
.navbar {
    background-color: #E50914;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    height: 60px;
    border-bottom: 4px solid #B20610;
    font-weight: bold;
}

/* Centralize and equally space the icons */
.navbar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Adjust icon styling for mobile */
.navbar-icons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0;
    padding-top: 5px;
    margin: 0;
    list-style: none;
}

.navbar-icons li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

/* Icon styling */
.navbar-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    filter: brightness(0) invert(1);
    /* This will make the icon white */
}

.trending-icon {
    width: 38px;
    height: 38px;
}

/* Hover effect on icons */
.navbar-icon:hover {
    transform: scale(1.02);
}

/* Hover effect on text elements */
.navbar-text,
.navbar-left a,
.navbar-right a,
.navbar-right button {

    text-decoration: none;
    font-size: 1.3rem !important;
}

/* Apply hover effect specifically on the text elements */
.navbar-left a:hover,
.navbar-right a:hover,
.navbar-right button:hover {
    color: #B20610;
    /* Increase size slightly */
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
    .navbar-text {
        display: none;
    }

    .navbar-desktop-links {
        display: none;
    }
}

/* Desktop specific adjustments */
@media (min-width: 769px) {
    .navbar-icons {
        display: none;
    }

    .navbar-desktop-links {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
    }

    .navbar-left {
        display: flex;
        align-items: center;
    }

    .navbar-right {
        display: flex;
        align-items: center;
    }

    .navbar-left li,
    .navbar-right li {
        list-style: none;
        margin: 0 40px;
    }

    .navbar-left a,
    .navbar-right a,
    .navbar-right button {
        color: #FFFFFF;
        text-decoration: none;
        background: none;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        transition: color 0.3s ease;
    }

    .navbar-left a:hover,
    .navbar-right a:hover,
    .navbar-right button:hover {
        color: #B20610;
    }
}