@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.trending-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    justify-items: center;
}

.trending-card {
    border: 1px solid;
    border-radius: 8px;
    overflow: hidden;
    text-align: left;
    width: 275px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease;
    height: 530px;
}

.trending-card:hover {
    transform: scale(1.02);
}

.trending-poster {
    width: 100%;
    object-fit: cover;
    height: 407px;
}

.trending-content {
    padding-left: 10px;
    flex-grow: 1;
}


.trending-title-text {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 1.2rem;
    margin: 25px 0 15px 0;
}

.trending-date {
    font-size: 0.9rem;
    margin: 0;
}

.trending-genre {
    font-size: 0.9rem;
    margin: 0;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

/* Remove underline from links and make the entire card clickable */
.trending-grid a {
    text-decoration: none;
    color: inherit;
    /* Ensures the text color doesn't change */
}

.trending-grid a:hover {
    text-decoration: none;
    /* Ensure there's no underline on hover */
}

.filter-panel {
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    /* Aligns the two boxes side by side */
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

/* Adjust the layout when the elements start to wrap */
@media (max-width: 1200px) {

    .filter-panel h3,
    .filter-panel h4 {
        text-align: center;
        /* Directly centers the text */
        width: 100%;
        /* Ensures it spans across the container */
    }

    .filter-panel {
        justify-content: center;
    }
}

.media-type-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align to left */
}

.media-type-filter {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.provider-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* Align to right */
}

.filter-button {
    background-color: #333;
    color: #fff;
    padding: 8px 16px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
}

.filter-button.active {
    background-color: #E50914;
    color: white;
    font-weight: 600;
}

.filter-button:hover {
    background-color: #E50914;
    color: white;
}

.provider-select {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
}

.provider-checkbox {
    background-color: #2a2a2a;
    padding: 8px 16px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s ease;
    font-weight: 400;
}

.provider-checkbox input {
    display: none;
}

.provider-checkbox.active {
    background-color: #E50914;
    color: #fff;
    font-weight: 600;
}

.provider-checkbox:hover {
    background-color: #B20610;
}

.filter-panel h3,
.filter-panel h4 {
    margin: 0px 0px 10px 0px;
    justify-content: space-between;
    /* Removes the margin from both h3 and h4 within .filter-panel */
}