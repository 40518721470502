:root {
    --scrollbar-width: 0px;
}

@supports (scrollbar-width: thin) {
    :root {
        --scrollbar-width: 16px;
        /* Standard scrollbar width */
    }
}

/* Styling the scrollbar track (the background) */
::-webkit-scrollbar {
    width: 15px;
}

/* Styling the background of the scrollbar */
::-webkit-scrollbar-track {

    /* Grey background */
    border-radius: 10px;
}

/* Styling the thumb (the draggable part, red ball) */
::-webkit-scrollbar-thumb {
    background-color: #E50914;
    /* Netflix Red */
    border-radius: 10px;
    /* Create a padding effect to look like a ball */
}

/* Optionally style when hovering over the scrollbar */
::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
}