body {
    margin: 0;
}

.recommended-container {
    text-align: center;
    overflow-y: auto;
    height: 100%;
    animation: fadeIn 0.5s ease forwards;
}

.recommended-title {
    color: #E50914;
    margin: 30px 0;
}

.recommended-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    justify-items: center;
}

.recommended-card {
    border: 1px solid;
    border-radius: 8px;
    overflow: hidden;
    text-align: left;
    width: 275px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease;
    height: 530px;
}

.recommended-card:hover {
    transform: scale(1.02);
}

.recommended-poster {
    width: 100%;
    object-fit: cover;
    height: 407px;
}

.recommended-content {
    padding-left: 10px;
    flex-grow: 1;
}


.recommended-title-text {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 1.2rem;
    margin: 25px 0 15px 0;
}

.recommended-date {
    font-size: 0.9rem;
    margin: 0;
}

.recommended-genre {
    font-size: 0.9rem;
    margin: 0;
}

/* Remove underline from links and make the entire card clickable */
.recommended-grid a {
    text-decoration: none;
    color: inherit;
    /* Ensures the text color doesn't change */
}

.recommended-grid a:hover {
    text-decoration: none;
    /* Ensure there's no underline on hover */
}