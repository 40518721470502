.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    z-index: 10;
    border-top: 2px solid white;
}

.app-info-language-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    /* Adjust as necessary */
}

.app-info-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px !important;
    /* Space between the links */
}

.language-select-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    /* Adds space between "Language:" label and dropdown */
}

.language-select {
    width: 150px;
}

.country-select {
    width: 150px;
}

.country-select-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;
}

.language-label {
    font-weight: 500;
}


.footer-link {
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;
}


.footer-link:hover {
    color: #E50914;
    /* Change color on hover */
    text-decoration: none;
    /* Keep no underline on hover */
}

.success-message,
.error-message {
    padding-top: 10px !important;
}

.admin-message-section {
    margin-top: 40px !important;
}

.close-button {
    padding: 0 !important;
    margin-top: 0 !important;
    font-weight: bold !important;
}

.switch-mode-button {
    font-weight: bold !important;
}

.send-message-button {
    font-weight: bold !important;
}

.footer-left {
    flex-grow: 1;
    /* Make sure the left side spans as much as it needs */
}


.MuiDialog-paper {
    box-shadow: none !important;
    /* Ensure the dialog box itself has no shadow */
}

.footer-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer-cogwheel-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

/* Light mode cogwheel style */
.footer-cogwheel-icon.light-mode {
    filter: brightness(0) invert(0);
    /* Normal black icon for light mode */
}

/* Dark mode cogwheel style */
.footer-cogwheel-icon.dark-mode {
    filter: brightness(0) invert(1);
    /* Inverted white icon for dark mode */
}

.copyright-text {
    margin-left: 20px;
}