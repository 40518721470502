.results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    justify-items: center;
    margin-top: 30px;
}

.results-card {
    border: 1px solid;
    border-radius: 8px;
    overflow: hidden;
    text-align: left;
    width: 275px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease;
    height: 530px;
    position: relative;
}

.results-card:hover {
    transform: scale(1.02);
}

.results-poster {
    width: 100%;
    object-fit: cover;
    height: 407px;
}

.results-content {
    padding-left: 10px;
    flex-grow: 1;
}

.results-title {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 1.2rem;
    margin: 25px 0 15px 0;
}

.results-release-date {
    font-size: 0.9rem;
    margin: 0;
    font-weight: 400;
}

.results-genre {
    font-size: 0.9rem;
    margin: 0;
    font-weight: 400;
}

/* Styling for No Media Message */
.no-media {
    color: #E50914 !important;
}