.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.loader-logo {
    width: 40px;
    /* Adjust size */
    animation: breathing 2s infinite ease-in-out;
}

@keyframes breathing {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}

/* Loading Text Styling */
.loader-container p {
    margin-left: 10px;
    font-weight: bold;
    animation: breathing 2s infinite ease-in-out;
    font-family: "Press Start 2P" !important;
}