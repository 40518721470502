/* src/index.css */
@import './styles/Scrollbar.css';

/* Add this to your CSS file */
@keyframes fadeinGeneral {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  padding-top: 60px;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.content-container {
  display: flex;
  flex-direction: column;
  /* Offset by navbar height */
  height: calc(100vh - 102px);
  /* Fill remaining space */
  overflow-y: auto;
  /* This will make the content scrollable */
  padding-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Styles for the "Seen!" bubble */
.seen-bubble {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #E50914;
  color: white;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
  text-transform: uppercase;
  animation: fadeinGeneral 0.5s ease-in-out forwards;
}

/* Styles for the "Our Recommendation" bubble */
.recommendation-bubble {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffffff;
  /* Gold color for recommendation */
  color: #E50914;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1;
  text-transform: uppercase;
  animation: fadeinGeneral 0.5s ease-in-out forwards;
}

.beta-notice-text {
  margin-bottom: 30px !important;
}

.beta-notice-instruction {
  margin-bottom: 30px !important;
  font-weight: bold !important;
}

/* Ensure the card is positioned relatively */
.watchhistory-card,
.watchlater-card,
.recommended-card,
.trending-card {
  position: relative;
}

.fadein {
  animation: fadeinGeneral 0.5s ease-in-out forwards;
}