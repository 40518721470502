.profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    animation: fadeinGeneral 0.2s ease-in-out forwards;
}

.profile-title {
    margin-bottom: 50px;
    margin-top: 75px;
    font-size: 3rem !important;
    font-weight: 600;
    color: #E50914;
    font-family: "Press Start 2P" !important;
}

@media (max-width: 768px) {
    .profile-title {
        font-size: 1.5rem !important;
    }
}

.profile-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 500px;
}

.profile-buttons-info {
    margin-top: 20px !important;
}

.profile-buttons-recommended {
    margin-top: 20px !important;
}

.profile-buttons-watchlist {
    margin-top: 20px !important;
}

.profile-buttons-history {
    margin-top: 20px !important;
}

.profile-buttons button {
    background-color: #E50914;
    border-radius: 12px;
    text-transform: none;
    font-size: 1.3rem;
    padding: 8px 24px;
    transition: transform 0.2s;
    font-weight: bold;
}

.profile-buttons button:hover {
    transform: scale(1.02);
    background-color: #B20610;
}