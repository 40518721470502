/* src/styles/Register.css */

.register-container {
    margin-top: 200px;
}

.register-title {
    text-align: center;
    font-weight: bold !important;
}

.register-form {
    display: flex;
    flex-direction: column;
}


.register-button {
    margin-bottom: 20px !important;
    margin-top: 5px !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
}