.user-settings-container {
    padding: 30px;
    max-width: 600px;
    margin: 0 auto;
}

.settings-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 50px !important;
    margin-top: 20px !important;
    text-align: center;
    font-weight: bold !important;
}

.settings-section {
    margin-bottom: 20px;
}

.settings-section h6 {
    font-weight: bold !important;
    margin-bottom: 30px !important;
}

.settings-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.success-message {
    color: #49da5f;
    text-align: center;
}

.error-message {
    color: #E50914;
    text-align: center;
}

.information-change-button-submit {
    font-weight: bold !important;
    margin-top: 10px !important;
}