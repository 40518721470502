.home-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    padding-top: 80px;
}

.home-title {
    color: #E50914;
    font-size: 4rem !important;
    font-weight: bold;
    margin-bottom: 30px;
    white-space: normal;
    display: inline-block;
    text-align: center;
    opacity: 0;
    animation: fadeinGeneral 0.5s ease-in-out forwards, blinkColorTitle 10s ease-in-out infinite;
    font-family: "Press Start 2P" !important;
}

.intro-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 50px;
    animation: fadeIn 1s ease-in-out;
}

.home-text {
    font-size: 2.5rem !important;
    font-family: "Press Start 2P" !important;
    font-weight: bold;
    margin-top: 0;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards, hoverUpDown 2s ease-in-out 1s infinite;
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

.home-text-link {
    text-decoration: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInSide {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes hoverUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}


.quiz-container {
    padding: 60px 60px 60px 60px !important;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quiz-question {
    margin-bottom: 40px !important;
    font-size: 1.5rem;
    font-weight: bold !important;
}

.quiz-options {
    display: grid;
    gap: 20px;
    justify-items: center;
    align-items: center;
    width: 100%;
    grid-auto-rows: minmax(50px, auto);
}

.even-options {
    grid-template-columns: repeat(2, 1fr);
}

.odd-options {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
}

.odd-options>.last-option {
    grid-column: 1 / span 2;
    justify-self: center;
}

.quiz-option {
    width: 100%;
    max-width: 160px;
    text-align: center;
    padding: 10px;
    color: white;
    background-color: #E50914;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold !important;
    transition: background-color 0.3s ease;
}


.next-question-btn {
    margin-top: 40px !important;
    font-weight: bold !important;
}

.quiz-option:hover {
    background-color: #1f1f1f;
}



/* Mobile-specific styling */
@media (max-width: 768px) {
    .home-title {
        font-size: 3rem !important;
    }
}


.quiz-container {
    margin-bottom: 40px !important;
    font-size: 1.5rem;
    font-weight: bold;
    color: inherit;
    animation: fadeinGeneral 0.3s ease-in-out;
}

.quiz-option.selected {
    background-color: red;
    color: white;
}

.next-question-btn {
    margin-top: 16px;
}