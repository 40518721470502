/* src/styles/Login.css */

.login-container {
    margin-top: 200px;
}

.login-title {
    text-align: center;
    font-weight: bold !important;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-field {
    margin-bottom: 20px;
}

/* Optional button-specific styles */
.login-button {
    margin-bottom: 20px !important;
    margin-top: 5px !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
}

/* Autofill styling for inputs */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #333 inset !important;
    -webkit-text-fill-color: #FFFFFF !important;
}