.media-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    overflow: visible;
    animation: fadeIn 0.5s ease forwards;
}

.media-poster {
    max-width: 100%;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
}

.media-trailer {
    width: 100%;
    height: 35vw;
    margin: 0 auto;
}

.poster-wrapper {
    position: relative;
    display: inline-block;
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
}

.poster-wrapper:hover .play-overlay {
    opacity: 1;
}

.play-button {
    font-size: 3rem;
    color: #E50914;
    pointer-events: none;
}

.play-text {
    font-size: 1.1rem;
    color: white;
    margin: 12px 0 0 10px;
    pointer-events: none;
}

.media-details {
    margin-top: 20px;
}

.media-title {
    font-size: 2rem;
    color: #E50914;
    margin-bottom: 20px;
}

.media-overview {
    font-size: 1.2rem;
    margin-top: 20px;
    line-height: 1.5;
    /* Increased line height for readability */
}

.release-date,
.media-info {
    font-size: 1rem;
}

.cast-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.cast-member {
    width: 150px;
    text-align: center;
    margin: 10px;
}

.cast-member img {
    width: 100%;
    border-radius: 10px;
}

/* General adjustments for media actions */
.media-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    /* This makes sure the tooltip stays within context */
    z-index: 10;
    /* Ensuring it's lower than the tooltip */
}

/* Action icon wrapper for buttons */
.action-icon-wrapper {
    position: relative;
    /* Ensure relative positioning */
    display: inline-block;
    margin: 0 10px;
    background-color: #E50914;
    border-radius: 4px;
    padding: 8px 16px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s, filter 0.3s;
    z-index: 1;
    /* Lower than the tooltip */
}

.action-icon-wrapper:hover {
    transform: scale(1.02);
}

.action-icon {
    width: 25px;
    height: 25px;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
}

/* Tooltip styles - Position under the button */
.action-icon-wrapper[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 120%;
    /* Place it below the button */
    left: 50%;
    /* Center it horizontally */
    transform: translateX(-50%);
    /* Center-align it */
    padding: 6px 10px;
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 5px;
    white-space: normal;
    max-width: 200px;
    width: max-content;
    text-align: center;
    line-height: 1.2;
    z-index: 999;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    pointer-events: none;
}

/* Tooltip arrow - pointing upwards */
.action-icon-wrapper[data-tooltip]:hover::before {
    content: '';
    position: absolute;
    top: 100%;
    /* Arrow at the top of the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.85) transparent transparent transparent;
    z-index: 999;
}

/* Adjust action icon wrapper to prevent overlap */
.action-icon-wrapper {
    margin: 0 10px;
    position: relative;
    z-index: 1;
    /* Give this a lower z-index than the tooltip */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .action-icon-wrapper[data-tooltip]:hover::after {
        font-size: 0.6rem;
        max-width: 150px;
    }


    .media-poster {
        max-width: 90%;
        margin: 0 auto;
    }

    .play-overlay {
        max-width: 90%;
        margin: 0 auto;
    }

    .media-title {
        font-size: 1.4rem;
        /* Reduced title font size on mobile */
    }

    .media-overview {
        font-size: 0.9rem;
        /* Decreased font size on mobile */
        line-height: 1.4;
        /* Slightly reduced line height */
        margin-top: 10px;
    }

    .release-date,
    .media-info {
        font-size: 0.85rem;
        /* Decreased font size for media info on mobile */
    }

    .media-trailer {
        height: 50vw;
    }

    .play-button {
        font-size: 3rem;
        margin: 0 0 20px 50px;
    }

    .play-text {
        font-size: 1.1rem;
        margin-right: 50px;
    }
}